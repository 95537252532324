import PropTypes from 'prop-types';
import { motion } from "framer-motion";
import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import {
  Box,
  Flex,
  Text,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  HStack,
  useMediaQuery,
  VStack,
  Center
} from '@chakra-ui/react'
import {
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons'
import { Squeeze as Hamburger } from 'hamburger-react'
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/react';
import Logo from "../../ui/Logo"

export default function Navbar() {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [isSmallerScreen] = useMediaQuery("(max-width: 48em)");

  // Framer Motion
  const fadeInOut = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  // Add window resize listener
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768 && isOpen) {
        onClose();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isOpen, onClose]);

  return (
    <Box
      position={["-webkit-sticky", "sticky"]}
      top={0}
      zIndex={1000}
      w="full"
      bgColor={"white"}
    >
      <VStack spacing={0}>
        {!isSmallerScreen && (
          <Center pb={30} w="full">
            <RouterLink to="/">
              <Logo m={'4'} justifyContent={'center'} w="250px" h="64px" zIndex={!isOpen ? 2 : 0} position="relative" />
            </RouterLink>
          </Center>
        )}
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          color={useColorModeValue('gray.600', 'white')}
          minH={'60px'}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.900')}
          align={'center'}
          justifyContent={"center"}
          w="full"
        >
          <Flex
            flex={{ base: 1, md: 'auto' }}
            ml={{ base: -2 }}
            display={{ base: 'flex', md: 'none' }}
            pl={2}
          >
            <Hamburger toggled={isOpen} toggle={onToggle} />
          </Flex>
          <HStack>
            {isSmallerScreen && (
              <RouterLink to="/">
                <Logo m={'4'} justifyContent={'center'} w="200px" h="64px" zIndex={!isOpen ? 2 : 0} position="relative" />
              </RouterLink>
            )}
            <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
              <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
                <DesktopNav />
              </Flex>
            </Flex>
          </HStack>
        </Flex>
      </VStack>
      {/* Mobile Nav Modal */}
      <Modal isOpen={isOpen} onClose={onToggle} isCentered size="full">
        <ModalOverlay />
        <ModalContent bg={useColorModeValue('white', 'gray.800')} p={4}>
          <Hamburger toggled={isOpen} toggle={onToggle} />
          <ModalBody display="flex" alignItems="center" justifyContent="center">
            <motion.div
              initial="hidden"
              animate={isOpen ? "show" : "hidden"}
              variants={fadeInOut}
            >
              <Stack spacing={8} textAlign="center" fontSize="2xl">
                <MobileNav />
              </Stack>
            </motion.div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}
const DesktopNav = () => {
  const location = useLocation();
  const linkColour = useColorModeValue('gray.600', 'gray.200')
  const popoverContentBgColour = useColorModeValue('white', 'gray.800')

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Box
                as={RouterLink}
                p={2}
                to={navItem.href ?? '#'}
                fontSize={'lg'}
                fontWeight={500}
                color={
                  (navItem.label === 'Learn' && (location.pathname === '/ndis' || location.pathname === '/aboutus'))
                  ? 'orange.500'
                  : (location.pathname === navItem.href ? 'orange.500' : linkColour)
                }
                _hover={{
                  textDecoration: 'none',
                  color: 'orange.500', 
                }}>
                {navItem.label}
              </Box>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColour}
                p={4}
                rounded={'xl'}
                minW={'sm'}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  )
}


const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <Box
      as={RouterLink}
      to={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('orange.50', 'gray.900') }}>
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'orange.400' }}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'lg'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'orange.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>
  )
}

DesktopSubNav.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string,
  subLabel: PropTypes.string,
};



const MobileNav = () => {
  return (
    <Stack bg={useColorModeValue('white', 'gray.800')} p={4} display={{ md: 'none' }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  )
}

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Box
        py={2}
        as={RouterLink}
        to={href ?? '#'}
        justifyContent="space-between"
        alignItems="center"
        _hover={{
          textDecoration: 'none',
        }}>
        <Text fontWeight={600} color={useColorModeValue('gray.600', 'gray.200')}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Box>



      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children &&
            children.map((child) => (
              <Box as={RouterLink} to={child.href} key={child.label} py={2}>
                {child.label}
              </Box>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  )
}

MobileNavItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.array,
  href: PropTypes.string,
};


const NAV_ITEMS = [
  {
    label: 'Home',
    href: '/',
  },
  {
    label: 'Our Services',
    href: '/services',
  },
  {
    label: 'Learn More',
    children: [
      {
        label: 'NDIS',
        subLabel: 'Information about the National Disability Insurance Scheme.',
        href: '/ndis',
      },
      {
        label: 'About Feliz',
        subLabel: 'Learn more about our organization and team.',
        href: '/aboutus',
      },
    ],
  },
  {
    label: 'Contact Us',
    href: '/contactus',
  },
]

