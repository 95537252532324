import React from "react";
import NDISLayout from "../components/layouts/NDISLayout";
import NDISHero from "../components/sections/ndis-sections/NDISHero"

export default function NDIS() {
  return (
    <NDISLayout>
      {/* Sections */}
      <NDISHero/>
    </NDISLayout>
  );
}
