import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Landing from './pages/Landing';
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs"
import NDIS from "./pages/NDIS";
import Services from "./pages/Services";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";

// Custom hook to scroll to top on route change
function useScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

function RouteWrapper() {
  useScrollToTop();  // Use the custom hook here

  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/contactus" element={<ContactUs />} />
      <Route path="/ndis" element={<NDIS />} />
      <Route path="/services" element={<Services />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
    </Routes>
  );
}

export default function App() {
  return (
    <Router>
      <RouteWrapper />
    </Router>
  );
}
