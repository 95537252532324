import {
  Box,
  Container,
  SimpleGrid,
  Stack,
  Text,
  Flex,
  useColorModeValue,
  HStack,
  Tag,
} from '@chakra-ui/react'
import {
  MdEmail,
  MdLocationOn
} from 'react-icons/md'
import { Link } from "react-router-dom";
import Logo from "../../ui/JustLogo";


const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={'500'} fontSize={'2xl'} mb={2}>
      {children}
    </Text>
  )
}

export default function Footer() {
  return (
    <Box fontSize={'1xl'} bg={"gray.50"} w="100%"> {/* Set width to 100% */}
      <Container as={Stack} maxW={'6xl'} py={4}> {/* maxW is set to "full" */}
        <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={8}>

          {/* LEARN */}
          <Stack align={'flex-start'}>
            <ListHeader>Learn More</ListHeader>
            <Box><Link  to="/ndis">NDIS Support Coordination</Link></Box>
            <Box><Link to="/aboutus">About Us</Link></Box>
          </Stack>

          {/* COMPANY */}
          <Stack align={'flex-start'}>
            <ListHeader>Company</ListHeader>
            <Box><Link to="/services">Services</Link></Box>
            <Box><Link to="/contactus">Contact Us</Link></Box>
          </Stack>

          {/* LEGAL */}
          <Stack align={'flex-start'}>
            <ListHeader>Legal</ListHeader>
            <Box><Link to="/privacy">Privacy Policy</Link></Box>
            <Box><Link to="/terms">Code of Conduct</Link></Box>
          </Stack>

          {/* CONTACT */}
          <Stack align={'flex-start'}>
            <HStack spacing={2}>
              <ListHeader>Contact</ListHeader>
              <Tag size={'md'} bg={'#F1916D'} color={'white'}>
                Email Us Below!
              </Tag>
            </HStack>
            
            <HStack>
              <MdLocationOn size={20}/>
              <Box>
                Melbourne, Victoria
              </Box>
            </HStack>

            <HStack>
              <MdEmail  size={20}/>
              <Box as="a" href={'mailto:felizserviceco@outlook.com '}>
              felizserviceco@outlook.com 
              </Box>
            </HStack>
          </Stack>

        </SimpleGrid>
      </Container>
      <Box py={4}>
        <Flex
          align={'center'}
          _before={{
            content: '""',
            borderBottom: '1px solid',
            borderColor: useColorModeValue('gray.200', 'gray.700'),
            flexGrow: 1,
            mr: 8,
          }}
          _after={{
            content: '""',
            borderBottom: '1px solid',
            borderColor: useColorModeValue('gray.200', 'gray.700'),
            flexGrow: 1,
            ml: 8,
          }}>
            <Link to={"/"}><Logo width="70px" /></Link>
        </Flex>

        <Text pt={6} fontSize={'sm'} textAlign={'center'}>
          © 2023 Feliz. All rights reserved
          <Box><Link to="/sitemap.xml" target="_blank" rel="noopener noreferrer">Sitemap</Link></Box>
        </Text>
      </Box>
    </Box>
  )
}

