import {
    Box,
    Container,
    Heading,
    SimpleGrid,
    Text,
    Stack,
    VStack,
    useBreakpointValue,
    Divider,
    Center,
    Button,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const features = [
    {
        id: 0,
        title: 'Individualized Planning',
        text: 'We meticulously create personalized support plans tailored to address the unique needs and goals of each participant, ensuring a clear pathway towards achieving their individual objectives.'
    },
    {
        id: 1,
        title: 'Community Integration',
        text: 'Our dedicated team assists participants in engaging and integrating with community and mainstream supports, fostering a sense of belonging and active participation in community life.'
    },
    {
        id: 2,
        title: 'Capacity Building',
        text: 'We are committed to enhancing the skills and resilience of participants, working closely with them to foster increased independence and improved well-being over time.'
    },
    {
        id: 3,
        title: 'Liaison with Providers',
        text: 'Our coordinators liaise with service providers to ensure the seamless delivery of required supports, fostering a collaborative environment that benefits all stakeholders.'
    },
    {
        id: 4,
        title: 'Crisis Resolution',
        text: 'Our team is prepared to provide timely intervention and support during crises, ensuring the safety, stability, and well-being of participants at all times.'
    },
    // {
    //     id: 5,
    //     title: 'NDIS Application Assistance',
    //     text: "Has your situation changed? Is your NDIS funding almost running out? Do you need to update your plan? Then it's time for a review and reassessment. Plan reviews involve discussing and assessing your plan with the NDIS and possibly making changes. They occur at the end of each plan cycle which is typically every 12 months or longer for some plans."
    // },

    // {
    //     id: 6,
    //     title: 'Therapeutic Support',
    //     text: 'We offer therapeutic support to enhance the mental health and overall well-being of individuals, providing a holistic approach to support coordination.'
    // },
    // {
    //     id: 7,
    //     title: 'Employment Support',
    //     text: 'We assist individuals in finding and maintaining employment that aligns with their skills and interests, supporting them in achieving economic independence and fulfilling work.'
    // },
]


export default function ServMain() {
    return (
        <Box pt={10}>
            <Stack spacing={4} as={Container} maxW={'5xl'} textAlign={'center'}>
                <Heading fontSize={useBreakpointValue({ base: '4xl', md: '6xl' })}>Tailored Support for Your NDIS Journey</Heading>
                <Text fontSize={useBreakpointValue({ base: '2xl', md: '4xl' })} color={'gray.600'}>
                    At Feliz Support Coordination, we guide you through your NDIS plans with personalized services, ensuring you have the right supports to meet your life goals. Discover how we make your path to self-assurance and achievement smoother and more accessible.
                </Text>
            </Stack>
            <Center height='50px'>
                <Box width={{ base: "60%", md: "40%", lg: "30%" }}>
                    <Divider orientation='horizontal' borderWidth="3px" borderColor={"orange.600"} />
                </Box>
            </Center>
            <Container fontSize={useBreakpointValue({ base: '2xl', md: '2xl' })} maxW={'9xl'} mt={10}>
                <SimpleGrid columns={{ base: 1, md: 1, lg: 5 }} spacing={12}>
                    {features.map((feature) => (
                        <Box key={feature.id}>
                            <VStack align={'start'}>
                                <Text fontWeight={600}>{feature.title}</Text>
                                <Text color={'gray.600'}>{feature.text}</Text>
                            </VStack>
                        </Box>
                    ))}
                </SimpleGrid>
                <Center mt={10} >
                <Box maxW={'5xl'}>
            <VStack align={{ base: 'start', sm: 'start', md: 'center' }}> {/* Align left on smaller screens and center on medium and larger screens */}
                <Text fontWeight={600}>NDIS Application Assistance</Text>
                <Text color={'gray.600'}>Has your situation changed? Is your NDIS funding almost running out? Do you need to update your plan? Then it's time for a review and reassessment. Plan reviews involve discussing and assessing your plan with the NDIS and possibly making changes. They occur at the end of each plan cycle which is typically every 12 months or longer for some plans.</Text>
            </VStack>
        </Box>
                </Center>
                <Center p={10}>
                    <RouterLink to="/contactus">
                        <Button
                            fontSize={useBreakpointValue({ base: 'xl', md: '2xl' })}
                            p={6}
                            bg={'#F1916D'}
                            color={'white'}
                            rounded={'md'}
                            _hover={{
                                transform: 'translateY(-2px)',
                                boxShadow: 'lg',
                            }}
                        >
                            Book Now
                        </Button>
                    </RouterLink>
                </Center>
            </Container>
            <Center height='50px'>
                <Box width={{ base: "60%", md: "40%", lg: "30%" }}>
                    <Divider orientation='horizontal' borderWidth="3px" borderColor={"orange.600"} />
                </Box>
            </Center>
        </Box>
    )
}
