import React from "react";
import ContactUsLayout from "../components/layouts/ContactUsLayout";
import Form from "../components/sections/contact-us-sections/Form"

export default function ContactUs() {
  return (
    <ContactUsLayout>
       {/* Sections */}
      <Form />
    </ContactUsLayout>
  );
}
