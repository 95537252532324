import React from "react";
import PrivacyLayout from "../components/layouts/PrivacyLayout";
import PrivacyMain from "../components/sections/privacy-sections/PrivacyMain"

export default function Services() {
  return (
    <PrivacyLayout>
       {/* Sections */}
      <PrivacyMain />
    </PrivacyLayout>
  );
}
