import React from "react";
import TermsLayout from "../components/layouts/TermsLayout";
import CodeMain from "../components/sections/terms-sections/CodeMain"

export default function Services() {
  return (
    <TermsLayout>
      {/* Sections */}
    <CodeMain />
    </TermsLayout>
  );
}
