import {
    Box,
    Heading,
    Container,
    Text,
    Stack,
    UnorderedList,
    ListItem,
    Link
} from '@chakra-ui/react'

export default function NDISHero() {
    return (
        <>
            <Container maxW={'8xl'}>
                <Stack
                    as={Box}
                    textAlign={'left'}
                    spacing={{ base: 8, md: 14 }}
                    py={{ base: 20, md: 36 }}>

                    {/* What is NDIS */}
                    <Heading textAlign={'center'}
                        fontWeight={600}
                        fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                        lineHeight={'110%'}>
                        The National Disability Insurance Scheme <br />
                        <Text as={'span'} color={'purple.400'}>
                            (NDIS)
                        </Text>
                    </Heading>
                    <Heading>
                        What is NDIS?
                    </Heading>
                    <Text fontSize={{ base: 'lg', sm: '2xl', md: '2xl' }} color={'gray.500'}>
                        The National Disability Insurance Scheme (NDIS) is a scheme designed to support people with disabilities,
                        their families, and carers in Australia. It's a joint initiative funded and governed by the Australian and participating
                        state and territory governments. The rollout of NDIS started in July 2016 across Australia, excluding Western Australia where
                        it's still being trialed.
                    </Text>

                    {/* Main Components */}
                    <Heading>
                        Main Components
                    </Heading>
                    <Text fontSize={{ base: 'lg', sm: '2xl', md: '2xl' }} color={'gray.500'}>
                        The cornerstone of NDIS is the provision of individualized support packages to eligible individuals with disabilities. Upon its full implementation in 2019, it was projected that around 460,000 Australians would receive these individualized supports. Besides, NDIS plays a broader role in aiding individuals with disabilities to:
                    </Text>
                    <UnorderedList fontSize={{ base: 'lg', sm: '2xl', md: '2xl' }} color={'gray.500'}>
                        <ListItem>Access mainstream services like health, housing, and education.</ListItem>
                        <ListItem>Engage with community services such as sports clubs and libraries.</ListItem>
                        <ListItem>Maintain informal supports from family and friends.</ListItem>
                    </UnorderedList>

                    {/* Objectives and Principles */}
                    <Heading>
                        Objectives and Principles
                    </Heading>
                    <Text fontSize={{ base: 'lg', sm: '2xl', md: '2xl' }} color={'gray.500'}>
                        Established under the National Disability Insurance Scheme Act 2013, the NDIS aims at:
                    </Text>
                    <UnorderedList fontSize={{ base: 'lg', sm: '2xl', md: '2xl' }} color={'gray.500'}>
                        <ListItem>Fostering the independence and social and economic participation of individuals with disabilities.</ListItem>
                        <ListItem>Providing reasonable and necessary supports, including early intervention supports.</ListItem>
                        <ListItem>Enabling choice and control for individuals with disabilities in pursuing their goals and planning/delivering their supports.</ListItem>
                        <ListItem>Facilitating a nationally consistent approach to the access to, and the planning and funding of, supports for individuals with disabilities.</ListItem>
                        <ListItem>Promoting the provision of high-quality and innovative supports to individuals with disabilities.</ListItem>
                    </UnorderedList>


                    {/* NDIS Replacing the Previous System */}
                    <Heading>
                        NDIS Replacing the Previous System
                    </Heading>
                    <Text fontSize={{ base: 'lg', sm: '2xl', md: '2xl' }} color={'gray.500'}>
                        NDIS is set to significantly replace the existing disability care and support system provided under the National Disability Agreement (NDA). The previous system was considered 'underfunded, unfair, fragmented, and inefficient', offering little choice and no certainty of access to appropriate supports for people with disabilities. The NDIS aims to rectify these issues by providing a unified national scheme for long-term, high-quality care and support for all Australians experiencing significant disability.
                    </Text>

                    {/* Eligibility and Access */}
                    <Heading>
                        Eligibility and Access
                    </Heading>
                    <Text fontSize={{ base: 'lg', sm: '2xl', md: '2xl' }} color={'gray.500'}>
                        Individuals are eligible for NDIS support if they:
                    </Text>
                    <UnorderedList fontSize={{ base: 'lg', sm: '2xl', md: '2xl' }} color={'gray.500'}>
                        <ListItem>Are under 65 years of age.</ListItem>
                        <ListItem>Are an Australian citizen or hold a permanent visa or a Protected Special Category visa.</ListItem>
                        <ListItem>Have a permanent and significant disability that affects their ability to take part in everyday activities.</ListItem>
                    </UnorderedList>

                    {/* Support Categories */}
                    <Heading>
                        Support Categories
                    </Heading>
                    <Text fontSize={{ base: 'lg', sm: '2xl', md: '2xl' }} color={'gray.500'}>
                        NDIS provides various support categories including:
                    </Text>
                    <UnorderedList fontSize={{ base: 'lg', sm: '2xl', md: '2xl' }} color={'gray.500'}>
                        <ListItem>Daily living: Supports to improve a participant’s ability to complete daily living activities.</ListItem>
                        <ListItem>Home: Supports to live independently.</ListItem>
                        <ListItem>Health and wellbeing: Supports for improved health and wellbeing.</ListItem>
                        <ListItem>Lifelong learning: Supports to improve the skills needed for daily life.</ListItem>
                        <ListItem>Work: Supports to get a job or keep a job.</ListItem>
                        <ListItem>Social and community participation: Supports to enable social and community participation.</ListItem>
                        <ListItem>Relationships: Supports to develop positive relationships and improve interpersonal interactions.</ListItem>
                    </UnorderedList>

                    <Heading>
                        Learn More
                    </Heading>
                    <Text fontSize={{ base: 'lg', sm: '2xl', md: '2xl' }} color={'gray.500'}>
                        You can find more detailed information and the latest updates on the official
                        <Link href="https://www.ndis.gov.au/" color="blue.500" isExternal> NDIS Website.</Link>
                    </Text>
                </Stack>
            </Container>
        </>
    )
}
