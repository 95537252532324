import React from "react";
import Slider from "react-slick";
import { Box, Image, Center, AspectRatio } from "@chakra-ui/react";
import kidRopes from "../../../assets/Images/webp/KidRopes.webp";
import shoulderTouch from "../../../assets/Images/webp/ShoulderTouch.webp";
import hijabSmiling from "../../../assets/Images/webp/HijabSmiling.webp";
import clipBoard from "../../../assets/Images/webp/Clipboard.webp";
import smilingGlasses from "../../../assets/Images/webp/SmilingGlasses.webp";
import redTeen from "../../../assets/Images/webp/RedheadTeen.webp";
import physio from "../../../assets/Images/webp/Physio.webp";
import dsBlue from "../../../assets/Images/webp/DSblue.webp";
import headphones from "../../../assets/Images/webp/HeadPhones.webp";



export default function Carousel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000, 
  };

  return (
    <Box pb={10} w="full" h="full">
      <Slider {...settings}>
        {[shoulderTouch, kidRopes, headphones, hijabSmiling, clipBoard, redTeen, smilingGlasses, physio, dsBlue].map((src, index) => (
          <Center key={index}>
            <AspectRatio ratio={1} maxH={{ base: "600px", md: "800px" }}> 
              <Image
                src={src}
                alt="Support Images"
                objectFit="cover" 
              />
            </AspectRatio>
          </Center>
        ))}
      </Slider>
    </Box>
  );
}
