import React from 'react';
import { Box, Heading, Text, List, ListItem, Link } from '@chakra-ui/react';

export default function PrivacyMain() {
    return (
        <Box padding="5%"  margin="auto">
            <Heading textAlign={"center"} marginBottom="20px">Privacy Policy</Heading>
            <Text fontSize="sm" marginBottom="10px">Effective Date: August 10th, 2023</Text>

            <Heading size="md" marginBottom="10px">1. Introduction</Heading>
            <Text marginBottom="20px">
                Feliz ("we," "our," or "us") is committed to respecting and protecting the privacy of individuals who engage with our support coordination services. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information in accordance with the privacy laws and regulations governing our operations, including the National Disability Insurance Scheme (NDIS). By using our services, you consent to the practices described in this policy.
            </Text>

            <Heading size="md" marginBottom="10px">2. Information We Collect</Heading>
            <List spacing={3} marginBottom="20px">
                <ListItem><Text as="b">Personal Information:</Text> Name, contact details, date of birth, and other identifying information.</ListItem>
                <ListItem><Text as="b">NDIS-related Information:</Text> Details about your NDIS plan, goals, and support requirements.</ListItem>
                <ListItem><Text as="b">Payment Information:</Text> Billing and payment details when applicable.</ListItem>
                <ListItem><Text as="b">Communication Data:</Text> Records of communications with us, including phone calls and electronic communications. We may also collect personal information from third parties, such as healthcare providers or government entities, with your consent or as authorized by law.</ListItem>
            </List>

            <Heading size="md" marginBottom="10px">3. How We Use Your Information</Heading>
            <List spacing={3} marginBottom="20px" >
                <ListItem>- To provide support coordination services.</ListItem>
                <ListItem>- To communicate with you regarding your support plan and related matters.</ListItem>
                <ListItem>- To process payments when necessary.</ListItem>
                <ListItem>- To improve our services and customer experience.</ListItem>
                <ListItem>- To meet legal and regulatory requirements, including compliance with NDIS guidelines.</ListItem>
            </List>

            <Heading size="md" marginBottom="10px">4. Information Sharing</Heading>
            <List spacing={3} marginBottom="20px" >
                <ListItem>- NDIS agencies, service providers, and other relevant parties to coordinate services.</ListItem>
                <ListItem>- Legal and regulatory authorities when required by law.</ListItem>
                <ListItem>- Third-party service providers who assist in providing our services, under strict data protection agreements to ensure the security and confidentiality of your information.</ListItem>
            </List>

            <Heading size="md" marginBottom="10px">5. Your Privacy Rights</Heading>
            <List spacing={3} marginBottom="20px" >
                <ListItem>- Access and correct your personal information.</ListItem>
                <ListItem>- Request the deletion of your personal information under certain circumstances.</ListItem>
                <ListItem>- Withdraw consent for data processing when applicable.</ListItem>
                <ListItem>- Lodge a complaint regarding the handling of your personal information.</ListItem>
            </List>

            <Heading size="md" marginBottom="10px">6. Security</Heading>
            <Text marginBottom="20px">We implement robust security measures, including encryption, access controls, and monitoring, to protect your personal information from unauthorized access, disclosure, or alteration.</Text>

            <Heading size="md" marginBottom="10px">7. Data Retention</Heading>
            <Text marginBottom="20px">We retain your information only as long as necessary to fulfill the purposes outlined in this policy and in compliance with our data retention obligations under law.</Text>

            <Heading size="md" marginBottom="10px">8. Changes to Privacy Policy</Heading>
            <Text marginBottom="20px">We may update this Privacy Policy from time to time, and any changes will be posted on our website. We will notify individuals of significant changes through email or other means.</Text>

            <Heading size="md" marginBottom="10px">9. Complaints and Contact Information</Heading>
            <Text marginBottom="20px">If you have questions, concerns, or complaints about your privacy or this Privacy Policy, please contact us at <Link href="mailto:felizserviceco@outlook.com" color="blue.500">felizserviceco@outlook.com.</Link></Text>

            <Heading size="md" marginBottom="10px">10. Definitions</Heading>
            <List spacing={3} >
                <ListItem><Text as="b">Personal Information:</Text> Information that identifies or could reasonably be used to identify an individual.</ListItem>
                <ListItem><Text as="b">NDIS-related Information:</Text> Information related to an individual's participation in the National Disability Insurance Scheme.</ListItem>
                <ListItem><Text as="b">Payment Information:</Text> Information related to billing and payments for services provided.</ListItem>
                <ListItem><Text as="b">Communication Data:</Text> Records of communications between Feliz and individuals, including emails, phone calls, and text messages.</ListItem>
                <ListItem><Text as="b">Third-party Service Providers:</Text> Other companies or individuals that Feliz engages to carry out functions on its behalf.</ListItem>
                <ListItem><Text as="b">Data Processing:</Text> Any operation or set of operations performed on personal information, including collecting, recording, organizing, storing, altering, retrieving, using, disclosing, or deleting such information.</ListItem>
            </List>
        </Box>
    );
}
