import React from "react";
import LandingLayout from "../components/layouts/LandingLayout";
import Hero from "../components/sections/landing-sections/Hero";
import Carousel from "../components/sections/landing-sections/Carousel";
import Mid from "../components/sections/landing-sections/Mid";
import Last from "../components/sections/landing-sections/Last";

export default function Landing() {
  return (
    <LandingLayout>
       {/* Sections */}
      <Hero />
      <Mid />
      <Carousel />
      <Last />
    </LandingLayout>
  );
}
