import React from "react";
import ServicesLayout from "../components/layouts/ServicesLayout";
import ServMain from "../components/sections/services-sections/ServMain"
// import Testimonials from "../components/sections/services-sections/Testimonials"

export default function Services() {
  return (
    <ServicesLayout>
       {/* Sections */}
      <ServMain />
      {/* <Testimonials /> */}
    </ServicesLayout>
  );
}
