import React from 'react';
import { Box, Heading, Text, List, ListItem, ListIcon, Button, Center, useBreakpointValue } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";

export default function AboutMain() {
    return (
        <Box padding="5%" maxWidth="full" margin="auto">
            <Heading textAlign={"center"} marginBottom="20px">About <Text as={"span"} color={"orange.400"}>Feliz</Text></Heading>
            <Box fontSize={useBreakpointValue({ base: 'xl', md: '2xl' })} color={'gray.500'}>
                <Text marginBottom="20px">
                Feliz was founded to ease the journey of individuals with disabilities and their families through the complex landscape of the National Disability Insurance Scheme (NDIS). 
                The daunting task of navigating the NDIS inspired us to establish a company that embodies a helping hand, a caring heart, and a guiding light to those in need.

                </Text>
                <Text marginBottom="20px">
                The name "Feliz," which means "happy" in Spanish, mirrors our fundamental belief that every individual, irrespective of their circumstances, is deserving of happiness and a fulfilling life. 
                It symbolizes the aura of hope and positivity that we aspire to inject into the realm of support coordination.

                </Text>
                <Text marginBottom="20px">
                With the growth and evolution of Feliz, the culture of care deepens inspiring our team to uphold the legacy of creating a significant imprint in the world of support coordination. 
                </Text>
                Our joy is rooted in assisting others carve out their path to fulfillment.
                <Text marginBottom="20px">
                
                </Text>
                <Heading fontSize={useBreakpointValue({ base: '2xl', md: '3xl' })} color={'black'} size="md" marginBottom="10px">Our Core Values:</Heading>
                <List spacing={3}>
                    <ListItem>
                        <ListIcon as={CheckCircleIcon} color="green.500" />
                        <Text as={"span"} fontWeight={"bold"} color={"orange.400"}>Personalized Approach:</Text> We take a tailored approach to support coordination, recognizing the unique needs,
                        aspirations, and preferences of each NDIS participant. Our team collaborates with individuals and their families
                        to craft plans that are a precise fit to their needs.
                    </ListItem>
                    <ListItem>
                        <ListIcon as={CheckCircleIcon} color="green.500" />
                        <Text as={"span"} fontWeight={"bold"} color={"orange.400"}>Empathy and Compassion:</Text> Our devotion to empathy and compassion distinguishes us. We genuinely value the well-being
                        of our NDIS participants, engaging with understanding and kindness in every interaction. We are more than just a service
                        provider; we are a supportive companion on your journey.
                    </ListItem>
                    <ListItem>
                        <ListIcon as={CheckCircleIcon} color="green.500" />
                        <Text as={"span"} fontWeight={"bold"} color={"orange.400"}>Expertise and Experience:</Text> The Feliz team is a blend of seasoned support coordinators proficient in the intricacies of
                        the NDIS system. Our profound understanding of the NDIS guidelines, regulations, and available services guarantees
                        optimal support for our participants.
                    </ListItem>
                    <ListItem>
                        <ListIcon as={CheckCircleIcon} color="green.500" />
                        <Text as={"span"} fontWeight={"bold"} color={"orange.400"}>Collaborative Relationships:</Text> We value collaboration and foster robust relationships with NDIS participants,
                        their families, service providers, and the NDIS itself. This collective approach simplifies the coordination
                        process, driving more effective outcomes. We strive together to help participants achieve their goals, making their
                        success our paramount priority.
                    </ListItem>
                    <ListItem>
                        <ListIcon as={CheckCircleIcon} color="green.500" />
                        <Text as={"span"} fontWeight={"bold"} color={"orange.400"}>Transparency and Communication</Text>: We emphasize transparency and open communication, keeping participants informed
                        about their NDIS plans, budgets, and progress at every juncture, thus enabling informed decisions.
                    </ListItem>
                    <ListItem>
                        <ListIcon as={CheckCircleIcon} color="green.500" />
                        <Text as={"span"} fontWeight={"bold"} color={"orange.400"}>Community Engagement:</Text> Extending beyond conventional support coordination, Feliz is dedicated to community engagement.
                        We link participants with local resources, support groups, and activities, enriching their quality of life and social
                        integration.
                    </ListItem>
                    <ListItem>
                        <ListIcon as={CheckCircleIcon} color="green.500" />
                        <Text as={"span"} fontWeight={"bold"} color={"orange.400"}>Flexibility:</Text> We acknowledge that circumstances can shift, and remain adaptable to these changes. Support plans are
                        adjustable to cater to evolving needs or preferences.
                    </ListItem>
                </List>
            </Box>

            <Center>
                <Button
                    colorScheme="orange"
                    size="lg"
                    mt={5}
                    onClick={() => window.location.href = '/contactus'}
                >
                    Contact Us
                </Button>
            </Center>

        </Box>
    );
};
