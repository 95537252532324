import {
    Box,
    Container,
    Heading,
    SimpleGrid,
    Icon,
    Text,
    Stack,
    HStack,
    VStack,
    useBreakpointValue,
    Divider,
    Center,
    Button,
} from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'
import { Link as RouterLink } from 'react-router-dom';

const features = [
    {
        id: 0,
        title: 'Individualized Planning',
        text: 'We create personalized support plans to address the unique needs of each participant.'
    },
    {
        id: 1,
        title: 'Community Integration',
        text: 'Assisting participants to engage and integrate with community and mainstream supports.'
    },
    {
        id: 2,
        title: 'Capacity Building',
        text: 'Working on skills and resilience for increased independence and improved well-being.'
    },
    {
        id: 3,
        title: 'Liaison with Providers',
        text: 'Liaising with service providers to ensure the delivery of required supports.'
    },
]

export default function Last() {
    return (
        <Box pb={20}>
            <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
                <Heading fontSize={useBreakpointValue({ base: '4xl', md: '6xl' })}>Services at <Text as={'span'} color={'orange.400'}>
                    <br /> Feliz Support Coordination
                </Text></Heading>
                <Text fontSize={useBreakpointValue({ base: '2xl', md: '4xl' })} color={'gray.600'}>
                    Our mission is to empower individuals to navigate their NDIS plans with confidence,
                    ensuring they have access to the supports that align with their life goals.
                </Text>
            </Stack>
            <Center height='50px'>
                <Box width={{ base: "60%", md: "40%", lg: "30%" }}>
                    <Divider orientation='horizontal' borderWidth="3px" borderColor={"orange.600"} />
                </Box>
            </Center>
            <Container fontSize={useBreakpointValue({ base: '2xl', md: '3xl' })} maxW={'10xl'} mt={10}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
                    {features.map((feature) => (
                        <HStack key={feature.id} align={'top'}>
                            <Box color={'green.400'} px={2}>
                                <Icon as={CheckIcon} />
                            </Box>
                            <VStack align={'start'}>
                                <Text fontWeight={600}>{feature.title}</Text>
                                <Text color={'gray.600'}>{feature.text}</Text>
                            </VStack>
                        </HStack>
                    ))}
                </SimpleGrid>
                <Center p={15}>
                    <RouterLink to="/services">
                        <Button
                            fontSize={useBreakpointValue({ base: 'xl', md: '2xl' })}
                            p={6}
                            bg={'#F1916D'}
                            color={'white'}
                            rounded={'md'}
                            _hover={{
                                transform: 'translateY(-2px)',
                                boxShadow: 'lg',
                            }}
                        >
                            Learn More About Our Services
                        </Button>
                    </RouterLink>
                </Center>

            </Container>
            <Center height='50px'>
                <Box width={{ base: "60%", md: "40%", lg: "30%" }}>
                    <Divider orientation='horizontal' borderWidth="3px" borderColor={"orange.600"} />
                </Box>
            </Center>
        </Box>
    )
}
