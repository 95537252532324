import React from 'react';
import { Box, Heading, Text, List, ListItem, Link } from '@chakra-ui/react';

export default function ConductMain() {
    return (
        <Box padding="5%"  margin="auto">
            <Heading textAlign={"center"} marginBottom="20px">Code of Conduct</Heading>

            <Heading size="md" marginBottom="10px">1. Adherence to NDIS Code of Conduct</Heading>
            <Text marginBottom="20px">
                At Feliz, our Support Coordinators are committed to upholding the 
                <Link href="https://www.ndiscommission.gov.au/about/ndis-code-conduct" color="blue.500" isExternal> NDIS Code of Conduct</Link>, 
                ensuring a high standard of service that promotes respect, 
                integrity, and transparency. Any concerns regarding abuse, neglect, 
                restrictive practices, child safety, and self-harm are taken seriously 
                and reported in accordance with NDIS guidelines.
            </Text>

            <Heading size="md" marginBottom="10px">2. Qualified and Experienced Staff</Heading>
            <List spacing={3} marginBottom="20px">
                <ListItem>- All staff complete the NDIS Worker Orientation module.</ListItem>
                <ListItem>- Training in Covid-19 Infection Control is provided.</ListItem>
                <ListItem>- Staff hold current Working With Children Checks.</ListItem>
                <ListItem>- Tertiary qualifications are held where appropriate.</ListItem>
                <ListItem>- All staff have a current police check.</ListItem>
                <ListItem>- Staff have passed the Victorian NDIS Worker Screening Check.</ListItem>
                <ListItem>- Minimum Diploma Level qualification with lived experience of disability is a requirement.</ListItem>
            </List>

            <Heading size="md" marginBottom="10px">3. Support Coordination Services</Heading>
            <List spacing={3} marginBottom="20px">
                <ListItem>- Assisting participants in understanding their NDIS plan.</ListItem>
                <ListItem>- Coordinating supports and services as per the participant’s plan.</ListItem>
                <ListItem>- Working with participants to explore their options and ensure they are receiving the most out of their plan.</ListItem>
                <ListItem>- Encouraging and supporting participant choice and control.</ListItem>
                <ListItem>- Building capacity for participants to exercise choice and control.</ListItem>
                <ListItem>- Monitoring and reporting on the provision of supports and outcomes achieved.</ListItem>
            </List>

            <Heading size="md" marginBottom="10px">4. Confidentiality and Privacy</Heading>
            <Text marginBottom="20px">
                We are committed to ensuring the confidentiality and privacy of our participants' information, 
                in accordance with the NDIS privacy guidelines and our 
                <Link color="blue.500" href="/privacy"> Privacy Policy</Link> for more information.
            </Text>

            <Heading size="md" marginBottom="10px">5. Feedback and Complaints</Heading>
            <Text marginBottom="20px">
                We value feedback from our participants and take all complaints seriously. 
                If you have any concerns, please contact us at 
                <Link href="mailto:felizserviceco@outlook.com" color="blue.500"> felizserviceco@outlook.com.</Link>
            </Text>

            <Heading size="md" marginBottom="10px">6. Continuous Improvement</Heading>
            <Text marginBottom="20px">
                We are dedicated to continuous improvement to provide high-quality support coordination services. 
                Feedback from participants and families is actively sought and used to inform our 
                ongoing improvement initiatives.
            </Text>
        </Box>
    );
}
