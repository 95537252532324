import React from "react";
import AboutUsLayout from "../components/layouts/AboutUsLayout.js";
import AboutUsMain from "../components/sections/about-us-sections/AboutMain.js"

export default function AboutUs() {
  return (
    <AboutUsLayout>
      {/* Sections */}
      <AboutUsMain />
    </AboutUsLayout>
  );
}
