import {
  Container,
  Heading,
  Stack,
  Text,
  Button,
  useBreakpointValue,
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom';

export default function Mid() {
  return (
    <Container maxW={'5xl'}>
      <Stack
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}>
        <Heading
          textAlign={'center'}
          fontWeight={600}
          fontSize={useBreakpointValue({ base: '4xl', md: '6xl' })}
          lineHeight={'110%'}>
          Support Coordination{' '}
          <Text as={'span'} color={'orange.400'}>
            Simplified
          </Text>
        </Heading>
        <Text textAlign={'left'} fontSize={useBreakpointValue({ base: '2xl', md: '4xl' })} color={'gray.500'}>
          At Feliz Support Coordination, we assist you in navigating your NDIS plan ensuring you make the most out of your supports.
          Our dedicated coordinators work alongside you to understand your needs, helping you to achieve your goals and lead an independent life.
        </Text>
        <Stack spacing={6} direction={'row'}>
        <RouterLink to="/contactus">          <Button
              fontSize={useBreakpointValue({ base: 'xl', md: '2xl' })}
              p={6}
              bg={'purple.400'}
              color={'white'}
              rounded={'md'}
              _hover={{
                transform: 'translateY(-2px)',
                boxShadow: 'lg',
              }}
            >
              Contact Us
            </Button></RouterLink>
            <RouterLink to="/aboutus">          <Button
              fontSize={useBreakpointValue({ base: 'xl', md: '2xl' })}
              p={6}
              bg={"#F1916D"}
              color={'white'}
              rounded={'md'}
              _hover={{
                transform: 'translateY(-2px)',
                boxShadow: 'lg',
              }}
            >
              Learn More
            </Button></RouterLink>
        </Stack>
      </Stack>
    </Container>
  )
}
