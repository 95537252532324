import React from "react";
import { Flex } from "@chakra-ui/react";
import Navbar from "../sections/universal-sections/Navbar";
import Footer from "../sections/universal-sections/Footer"

export default function ContactUsLayout(props) {
  return (
    <Flex
      direction="column"
      align="center"
      m="0 auto"
      {...props}
    >
      <Navbar />
      {props.children}
      <Footer />
    </Flex>
  );
}
