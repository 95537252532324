import { Stack, Flex, Button, Text, VStack, useBreakpointValue } from '@chakra-ui/react';
import heroImage from "../../../assets/Images/webp/Hero-Image.webp";
import { Link as RouterLink } from 'react-router-dom';

export default function Hero() {
  return (
    <Flex
      w={'full'}
      h={'70vh'}
      backgroundImage={`url(${heroImage})`}
      backgroundSize={'cover'}
      backgroundPosition={'center center'}
    >
      <VStack
        w={'full'}
        justify={'center'}
        px={useBreakpointValue({ base: 4, md: 8 })}
      >
        <Stack maxW={'2xl'} align={'center'} spacing={6}>
          <Text
            color={'white'}
            fontWeight={700}
            lineHeight={1.2}
            fontSize={useBreakpointValue({ base: '4xl', md: '6xl' })}
            textAlign={'center'}
            pt={20}
            pb={1}
          >
            Empowering Australia's Care Journey
          </Text>
          <Stack fontSize={{ base: "md", md: "lg", lg: "xl" }} e={"xl"} direction={'row'} justify={'center'}>
            <RouterLink to="/contactus">          <Button
              fontSize={useBreakpointValue({ base: 'xl', md: '2xl' })}
              p={6}
              bg={'#F1916D'}
              color={'white'}
              rounded={'md'}
              _hover={{
                transform: 'translateY(-2px)',
                boxShadow: 'lg',
              }}
            >
              Contact Us
            </Button></RouterLink>
          </Stack>
        </Stack>
      </VStack>
    </Flex>
  );
}
