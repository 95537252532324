import React from "react";
import { Box, Image } from "@chakra-ui/react";
import felizLogo from "../../assets/Logos/Feliz-Just-Logo-Transparent.png";

export default function Logo(props) {
  return (
    <Box {...props}>
      <Image
        src={felizLogo}
        alt="Feliz Logo"
        objectFit="contain"
        width="2xl"
      />
    </Box>
  );
}
